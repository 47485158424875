var structureOffsetPage = 1, backTimeout;

function loader(show) {
    if (show) {
        $('body').addClass('loading');
    }
    else {
        $('body').removeClass('loading');   
    }
}

function recaptchaCallback() {
    $('#c_recaptcha').valid();
}

jQuery(function($) {

    $( "#contact_form" ).validate( {
        rules: {
            structure: "required",
            fullname: "required",
            phone: "required",
            message: "required",
            email: {
                required: true,
                email: true
            },
            c_recaptcha: {
                required: function () {
                    if (grecaptcha.getResponse() == '') {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        },
        messages: {
            fullname: requiredFieldTxt,
            structure: requiredFieldTxt,
            phone: requiredFieldTxt,
            message: requiredFieldTxt,
            email: emailFieldTxt,
            c_recaptcha: captchaFieldTxt
        },
        errorElement: "span",
        errorPlacement: function ( error, element ) {
            error.addClass( "help-block" );
            error.insertAfter( element );
        },
        highlight: function ( element, errorClass, validClass ) {
            $( element ).parents( ".form-group" ).addClass( "has-error" ).removeClass( "has-success" );
        },
        unhighlight: function (element, errorClass, validClass) {
            $( element ).parents( ".form-group" ).addClass( "has-success" ).removeClass( "has-error" );
        },
        submitHandler: function (form) {
            sendForm(form);
        }
    } );

    function sendForm(form) {
        loader(true);
        var formFilterData = $('#contact_form').serialize();
        $('#login_error').fadeOut(250, function() {
            $('#login_error').remove();
        });
        var formTop = $('.site-content').offset().top;
        $.ajax({
            url : ajaxUri,
            type : 'post',
            dataType: 'json',
            data : {
                action : 'ajax_contact_form',
                security : formSecur,
                formData: formFilterData
            },
            success : function( response ) {
                loader(false);
                $('html, body').animate({
                    scrollTop:formTop
                }, 'slow');
                if (response.success) {
                    $('#contact_form_container').html(response.success);
                }
                else {
                    if (response.error) {
                        $('#contact_form_container').prepend(response.error);
                    }
                }
            },
            error : function( response ) {
                loader(false);
            }
        });
    }

});
