var publicationOffsetPage = 1, backTimeout;

function loader(show) {
    if (show) {
        $('body').addClass('loading');
    }
    else {
        $('body').removeClass('loading');   
    }
}

jQuery(function($) {
    $('#load_more_publications').on('click', function() {
        loader(true);
        var formFilterData = $('#filter_publications_form').serialize();
        $.ajax({
            url : ajaxUri,
            type : 'post',
            dataType: 'json',
            data : {
                action : 'ajax_list_publication',
                security : publicationListSecur,
                page : publicationOffsetPage,
                formData: formFilterData
            },
            success : function( response ) {
                loader(false);
                $('#publications_results').append(response);
                publicationOffsetPage++;
                if ($('.publications-item').length >= maxPagePublications) {
                    $('#load_more_publications').remove();
                }
            },
            error : function( response ) {
                loader(false);
            }
        });
    });
});