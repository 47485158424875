jQuery(function ($) {

    var winW = '';

    $(document).ready(init);

    function init() {
        resize();
        $(window).on('resize', resize);

        stickyNav();
        menu();
        select();
        slickSliders();
        search();
        share();
    }


    function resize() {
        winW = window.innerWidth;
    }

    function stickyNav() {
        $(window).scroll(function () {
            if ($('.site-header').length > 0) {
                $('.site-header').toggleClass('sticky', $(window).scrollTop() > 0);
            }
        });
    }

    function search() {
        // search modal
        $('.search-toggle').on('click', function (e) {
                e.preventDefault();

                $('#modal-search .search-field').val('');

                // Show modal
                $('#modal-search').modal();

                // Add focus to search field
                $('#modal-search').off('shown.bs.modal');
                $('#modal-search').on('shown.bs.modal', function () {
                    $('#modal-search .search-field').focus();
                });
            }
        );
    }

    function share() {
        // share modal
        $('.site-content--post .btn-share').on('click', function (e) {
                e.preventDefault();

                $('#modal-share input').val('');

                // Show modal
                $('#modal-share').modal();

                // Add focus to search field
                $('#modal-share').off('shown.bs.modal');
            }
        );
    }

    function menu() {
        // menu toggle
        $('.menu-toggle-ctn').on('click', function (e) {
            e.preventDefault();
            var parent = $('body');

            if (parent.hasClass('menu-opened')) {
                parent.removeClass('no-scroll');
            } else {
                parent.addClass('no-scroll');
            }

            $('site-header').toggleClass('open');
            parent.toggleClass('menu-opened');
        });

        $('.site-menu .overlay').on('click', function(e){
            e.preventDefault();
            var parent = $('body');
            parent.removeClass('no-scroll').removeClass('menu-opened');
            $('site-header').removeClass('open');
        });
    }

    function select() {
        if ($('select').length) {
            $('select').select2({
                minimumResultsForSearch: 20
            });
        }
    }

    function slickSliders() {

        if ($('.home-header').length) {
            if ($('.home-header .slider-img').length > 1) {
                $('.header-slider').slick({
                    autoplay: true,
                    autoplaySpeed: 3000,
                    infinite: true,
                    dots: true,
                    arrows: false,
                    adaptiveHeight: false,
                    speed: 500,
                    // fade: true,
                    cssEase: 'linear'
                });
            }
        }

    }


});